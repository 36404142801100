@import '../../styles/variables';

:export {
  variables: {
    veryLargeDesktopMediaQueryMaxWidth: $veryLargeDesktopMediaQueryMaxWidth;
    veryLargeDesktopMediaQueryMinWidth: $veryLargeDesktopMediaQueryMinWidth;
    largeDesktopMediaQueryMinWidth: $largeDesktopMediaQueryMinWidth;
    desktopMediaQueryMinWidth: $desktopMediaQueryMinWidth;
    tabletMediaQueryMaxWidth: $tabletMediaQueryMaxWidth;
    tabletMediaQueryMinWidth: $tabletMediaQueryMinWidth;
    mobileMediaQueryMaxWidth: $mobileMediaQueryMaxWidth;
  }
}

.HomePageContainer {
  .HomePageCardButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 36px;

    .HomePageCardButton {
      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }

  .HomePageFeaturesContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 36px;

    .HomePageFeatureTitlesContainer {
      width: 45%;
      margin-right: 18px;
      padding-right: 18px;

      .HomePageFeatureTitleContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: -36px;

        .HomePageFeatureIcon {
          height: 18px;
          min-height: 18px;
          width: 18px;
          min-width: 18px;
          object-fit: contain;
          margin-top: 4px;
        }

        .HomePageFeatureTextContainer {
          .HomePageFeatureTitle {
            font-size: 22px;
            font-weight: 700;
          }

          .HomePageFeatureText {
            font-size: 18px;
            font-weight: 400;
          }

          .HomePageFeatureTitle+.HomePageFeatureText {
            margin-top: 12px;
          }
        }

        .HomePageFeatureIcon+.HomePageFeatureTextContainer {
          margin-left: 18px;
        }

        &:not(:last-child) {
          margin-bottom: 36px;
        }
      }
    }

    .HomePageFeatureListContainer {
      width: 55%;
      margin-left: 18px;
      padding-left: 18px;

      .HomePageFeatureListItemContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        .HomePageFeatureListItemIcon {
          height: 18px;
          min-height: 18px;
          width: 18px;
          min-width: 18px;
          object-fit: contain;
          margin-top: 4px;
        }

        .HomePageFeatureListItemText {
          font-size: 20px;
          font-weight: 400;
        }

        .HomePageFeatureListItemIcon+.HomePageFeatureListItemText {
          margin-left: 18px;
        }

        &:not(:last-child) {
          margin-bottom: 18px;
        }
      }
    }

    .HomePageFeatureTitlesContainer+.HomePageFeatureListContainer {
      border-left: 1px solid var(--gray);
    }
  }

  .HomePageLinksContainer {
    margin-top: 36px;
  }

  @include for-mobile-and-tablet {
    .HomePageFeaturesContainer {
      .HomePageFeatureTitlesContainer {
        .HomePageFeatureTitleContainer {
          margin-left: 0px;
        }
      }
    }
  }

  @include for-mobile-only {
    .HomePageCardButtonsContainer {
      margin-top: 24px;
    }

    .HomePageFeaturesContainer {
      flex-direction: column;
      margin-top: 24px;

      .HomePageFeatureTitlesContainer {
        width: 100%;
        margin-right: 0px;
        padding-right: 0px;

        .HomePageFeatureTitleContainer {
          .HomePageFeatureTextContainer {
            .HomePageFeatureTitle {
              font-size: 18px;

              .HomePageFeatureText {
                font-size: 16px;
              }
            }

            .HomePageFeatureIcon+.HomePageFeatureTextContainer {
              margin-left: 12px;
            }

            &:not(:last-child) {
              margin-bottom: 24px;
            }
          }
        }
      }

      .HomePageFeatureListContainer {
        width: 100%;
        margin-left: 0px;
        padding-left: 0px;

        .HomePageFeatureListItemContainer {
          .HomePageFeatureListItemText {
            font-size: 18px;
          }

          .HomePageFeatureListItemIcon+.HomePageFeatureListItemText {
            margin-left: 12px;
          }

          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }
      }

      .HomePageFeatureTitlesContainer+.HomePageFeatureListContainer {
        margin-top: 24px;
        border-left: none;
      }
    }

    .HomePageLinksContainer {
      margin-top: 24px;

      .HomePageCardLink {
        padding-bottom: 4px;
      }
    }
  }

  @include for-tablet-only {
    .HomePageCardButtonsContainer {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 24px;

      .HomePageCardButton {
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }

    .HomePageFeaturesContainer {
      .HomePageFeatureListContainer {
        .HomePageFeatureListItemContainer {
          .HomePageFeatureListItemText {
            font-size: 20px;
          }
        }
      }
    }
  }
}

.BoostConversionWhatsappContainer {
  padding-top: 0px;

  .HomePageCardButtonsContainer {
    &.AppDownloadButtonsContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 24px;

      .HomePageCardButton {
        &.AppDownloadButton {
          background-color: var(--black);
          color: var(--white);
          border-color: var(--black);
          max-width: 50%;
          min-height: 43px;
          max-height: 56px;

          .HomePageCardButtonLink {
            color: var(--white);

            .HomePageCardButtonText {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              padding: 6px 8px;

              .AppDownloadButtonIconContainer {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                picture,
                img,
                svg {
                  height: 24px;
                  min-height: 24px;
                  width: 24px;
                  min-width: 24px;
                }
              }

              .AppDownloadButtonTextContainer {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin-left: 8px;
              }
            }
          }

          &:not(:last-child) {
            margin-right: 12px;
          }
        }
      }
    }
  }

  @include for-tablet-and-desktop {
    .HomePageCard {
      .HomePageCardImage {
        width: 50%;
      }

      .HomePageCardContent {
        width: 50%;

        .HomePageCardContentTitle + .HomePageCardContentDescription {
          margin-top: 0px;
        }
      }
    }
  }

  @include for-desktop-only {
    .HomePageCardButtonsContainer {
      &:not(.AppDownloadButtonsContainer) {
        .HomePageCardButton {
          width: 280px;
          max-width: 100%;
        }
      }
    }
  }

  @include for-tablet-only {
    .HomePageCardButtonsContainer {
      &.AppDownloadButtonsContainer {
        .HomePageCardButton {
          &.AppDownloadButton {
            &:not(:last-child) {
              margin-bottom: 0px;
            }
          }
        }
      }

      &:not(.AppDownloadButtonsContainer) {
        .HomePageCardButton {
          width: 253px;
          max-width: 100%;
        }
      }
    }
  }

  @include for-mobile-only {
    .HomePageCardButtonsContainer {
      width: 100%;

      .HomePageCardButton {
        width: 100%;
      }

      &.AppDownloadButtonsContainer {
        margin-top: 16px;

        .HomePageCardButton {
          &.AppDownloadButton {
            .HomePageCardButtonLink {
              .HomePageCardButtonText {
                padding: 8px 18px;
              }
            }

            &:not(:last-child) {
              margin-right: 12px;
            }
          }
        }
      }

      &:not(.AppDownloadButtonsContainer) {
        .HomePageCardButton {
          .HomePageCardButtonLink {
            .HomePageCardButtonText {
              padding: 12px 18px;
            }
          }
        }
      }
    }
  }

  @include for-very-small-mobile-only {
    .HomePageCardButtonsContainer {
      &.AppDownloadButtonsContainer {
        width: 100%;
        min-width: 100%;
        flex-direction: column;

        .HomePageCardButton {
          &.AppDownloadButton {
            width: 100%;
            min-width: 100%;

            &:not(:last-child) {
              margin-right: 0px;
              margin-bottom: 16px;
            }

            .AppDownloadButtonTextContainer {
              div {
                &:nth-child(1) {
                  font-size: 11px;
                  line-height: 12px;
                }

                &:nth-child(2) {
                  font-size: 13px;
                  line-height: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.PartnerLogosContainer {
  padding-left: 36px;
  padding-right: 36px;
  position: relative;

  .PartnerLogosListContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 64px;

    .PartnerLogosList {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: -24px;
      height: 240px;

      picture,
      img {
        object-fit: contain;
      }

      .PartnerLogo {
        padding: 24px;
        height: 120px;
        width: 25%;
        filter: grayscale(1);

        &:first-child {
          margin-left: 0px;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  @include for-mobile-only {
    padding-left: 24px;
    padding-right: 24px;

    .PartnerLogosListContainer {
      .PartnerLogosList {
        margin: -12px;
        height: 180px;

        .PartnerLogo {
          padding: 12px;
          height: 90px;
        }
      }
    }
  }
}

.BoostConversionPersonalCommerceContainer {
  .BoostConversionPersonalCommerceCardRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: -24px -36px 0px -36px;

    .BoostConversionPersonalCommerceCardColumn {
      width: calc(100% / 3);
      padding: 0px 36px;
    }
  }

  @include for-tablet-only {
    .BoostConversionPersonalCommerceCardRow {
      margin-left: -24px;
      margin-right: -24px;

      .BoostConversionPersonalCommerceCardColumn {
        width: 50%;
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }

  @include for-mobile-only {
    .BoostConversionPersonalCommerceCardRow {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin: 0px;

      .BoostConversionPersonalCommerceCardColumn {
        width: 100%;
        padding: 0px;
        margin: 0px;
        width: 100%;

        &:first-child {
          margin-top: -12px;
        }
      }
    }
  }

}

.BuiltWithQuicksellContainer {
  padding-bottom: 0px;

  .HomePageLinksContainer {
    margin-top: 18px;
  }

  .BuiltWithQuicksellCataloguesContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .BuiltWithQuicksellCatalogue {
      display: block;
      width: 100%;
      height: 100%;

      picture,
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }

  @include for-desktop-only {
    .BuiltWithQuicksellCataloguesContainer {
      margin: -24px;

      .BuiltWithQuicksellCatalogue {
        padding: 24px;

        picture,
        img {
          min-height: 300px;
          max-height: 350px;
        }
      }
    }
  }

  @include for-tablet-only {
    .BuiltWithQuicksellCataloguesContainer {
      margin: -18px -12px;

      .BuiltWithQuicksellCatalogue {
        padding: 18px 12px;

        picture,
        img {
          min-height: 200px;
          max-height: 300px;
        }
      }
    }
  }

  @include for-mobile-only {
    .BuiltWithQuicksellCataloguesContainer {
      margin: -12px;

      .BuiltWithQuicksellCatalogue {
        padding: 12px;

        picture,
        img {
          min-height: 300px;
          max-height: 350px;
        }
      }
    }

    .HomePageLinksContainer {
      margin-top: 12px;
    }
  }

  @include for-tablet-and-desktop {
    padding-top: 24px;
  }
}

.CatalogueECommerceContainer {
  padding-right: 0px;

  .HomePageFeaturesContainer {
    .HomePageFeatureTitlesContainer {
      .HomePageLinksContainer {
        margin-top: 18px;
      }
    }
  }

  .HomePageLinksContainer {
    margin-top: 24px;
  }

  @include for-mobile-only {
    padding-top: 0px;
    padding-bottom: 0px;

    .HomePageFeaturesContainer {
      .HomePageFeatureListContainer {
        margin-top: 36px !important;

        .HomePageFeatureListItemContainer {
          &:not(:last-child) {
            margin-bottom: 24px;
          }
        }
      }
    }

    .HomePageCard {
      .HomePageCardContent {
        padding-right: 36px !important;
      }
    }
  }

  @include for-desktop-only {
    padding-top: 36px;
    padding-bottom: 0px;

    .HomePageFeaturesContainer {
      .HomePageFeatureTitlesContainer {
        padding-right: 36px;
      }

      .HomePageFeatureListContainer {
        padding-left: 36px;
        border-left: none;
      }
    }

    .HomePageCard {
      .HomePageCardImage {
        width: 42.5%;
      }

      .HomePageCardContent {
        width: 57.5%;
      }
    }
  }

  @include for-tablet-and-desktop {
    .HomePageFeaturesContainer {
      .HomePageFeatureTitlesContainer {
        margin-right: 0px;
        padding-right: 24px;

        .HomePageFeatureTitleContainer {
          .HomePageFeatureIcon {
            margin-top: 7px;
          }
        }
      }

      .HomePageFeatureListContainer {
        margin-left: 0px;
        padding-left: 24px;

        .HomePageFeatureListItemContainer {
          .HomePageFeatureListItemIcon {
            margin-top: 7px;
          }
        }
      }
    }
  }

  @include for-tablet-only {
    padding-top: 0px;
    padding-bottom: 24px;
    padding-right: 36px;

    .HomePageFeaturesContainer {
      .HomePageFeatureTitlesContainer {
        width: 50%;
      }
      .HomePageFeatureListContainer {
        width: 50%;
      }
    }

    .HomePageLinksContainer {
      padding-left: 0px;
    }

    .HomePageCard {
      flex-direction: column !important;

      .HomePageCardImage {
        width: 60%;
        padding-left: 0px !important;
        padding-right: 0px;
      }

      .HomePageCardContent {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px !important;
      }
    }
  }
}

.ScaleBusinessOnWhatsappContainer {
  padding-top: 56px;

  .HomePageFeaturesContainer {
    .HomePageFeatureListContainer {
      width: 100%;
      margin-left: 0px;
      padding-left: 0px;
    }
  }

  @include for-desktop-only {
    padding-top: 0px;
  }

  @include for-tablet-and-desktop {
    .HomePageCard {
      .HomePageCardImage {
        width: 45%;
      }

      .HomePageCardContent {
        width: 55%;
      }
    }
  }

  @include for-mobile-only {
    padding-top: 12px;
    padding-bottom: 0px;
  }

  @include for-tablet-only {
    padding-top: 36px;
  }
}

.PowerfulFeaturesForB2bBusinessContainer {
  .HomePageFeaturesContainer {
    .HomePageFeatureTitlesContainer {
      width: 50%;
      width: 50%;

      .HomePageFeatureTitleContainer {
        .HomePageFeatureIcon {
          height: 24px;
          min-height: 24px;
          width: 24px;
          min-width: 24px;
          object-fit: contain;
          margin-top: 4px;
        }
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  @include for-desktop-only {
    .HomePageLinksContainer {
      padding-left: 8px;
    }
  }

  @include for-tablet-and-desktop {
    .HomePageCard {
      .HomePageCardImage {
        width: 50%;
      }

      .HomePageCardContent {
        width: 50%;
      }
    }
  }

  @include for-mobile-only {
    padding-top: 24px;
    padding-bottom: 12px;

    .HomePageFeaturesContainer {
      .HomePageFeatureTitlesContainer {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 24px;
        }

        .HomePageFeatureTitleContainer {
          .HomePageFeatureText {
            font-style: normal;
          }

          &:not(:last-child) {
            margin-bottom: 24px;
          }
        }
      }
    }

    .HomePageCard {
      .HomePageCardImage {
        picture,
        img {
          min-height: 300px;
          object-fit: contain;
        }
      }
    }
  }

  @include for-mobile-and-tablet {
    .HomePageLinksContainer {
      padding-left: 42px;
    }
  }

  @include for-tablet-only {
    .HomePageCard {
      flex-direction: column !important;

      .HomePageCardImage {
        width: 75%;
        padding-left: 0px !important;
      }

      .HomePageCardContent {
        width: 100%;
        margin-top: 36px !important;
        padding-right: 0px !important;
      }
    }
  }
}

.BuildYourOwnECommereAppContainer {
  .HomePageFeaturesContainer {
    margin-top: 24px;

    .HomePageFeatureListContainer {
      width: 100%;
      margin-left: 0px;
      padding-left: 0px;

      .HomePageFeatureListItemContainer {
        .HomePageFeatureListItemIcon {
          height: 24px;
          min-height: 24px;
          width: 24px;
          min-width: 24px;
          object-fit: contain;
          margin-top: 4px;
        }
      }
    }
  }

  .HomePageLinksContainer {
    margin-top: 24px;
  }

  .HomePageCard {
    .HomePageCardImage {

      picture,
      img,
      video,
      iframe {
        min-height: 400px;
        object-fit: contain;
      }
    }
  }

  @include for-mobile-only {
    .HomePageCard {
      .HomePageCardImage {
        picture,
        img,
        video,
        iframe {
          min-height: 300px;
        }
      }
    }
  }

  @include for-tablet-only {
    .HomePageCard {
      .HomePageCardImage {
        picture,
        img,
        video,
        iframe {
          min-height: 350px;
        }
      }
    }
  }

  @include for-tablet-and-desktop {
    .HomePageCard {
      .HomePageCardImage {
        width: 45%;
        margin-left: 0px;
        flex: 0 0 auto !important;
      }

      .HomePageCardContent {
        width: 50%;
        flex: 0 0 auto !important;
      }
    }
  }
}

.PlaystoreReviewsContainer {
  position: relative;
  padding-left: 36px;
  padding-right: 36px;
  margin-top: 72px;

  .PlaystoreReviewsBlurContainer {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    filter: blur(18px);
    background-image: url('/assets/playstore_review_background.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .PlaystoreReviewsListContainer {
    align-items: center;
    overflow: hidden;
    margin-left: -36px;
    margin-right: -36px;
    max-width: 1920px;

    .PlaystoreReviewsList {
      display: inline-flex;
      align-items: stretch;
      padding-left: 100%;
      animation-name: horizontal-scroll;
      animation-duration: 60s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-delay: 0s;
      margin-left: -100%;

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      &:first-child {
        margin-top: 36px;

        .PlaystoreReview {
          &:hover,
          &:focus,
          &:focus-within {
            transform-origin: top center;
          }
        }
      }

      &:last-child {
        margin-bottom: 36px;
        animation-delay: 2s;

        .PlaystoreReview {
          &:hover,
          &:focus,
          &:focus-within {
            transform-origin: bottom center;
          }
        }
      }

      .PlaystoreReview {
        min-width: 300px;
        padding: 14px 18px;
        justify-content: space-between;
        transition: transform 0.2s ease;
        z-index: 1;

        .PlaystoreReviewUser {
          margin-top: 12px;
        }

        &:hover,
        &:focus,
        &:focus-within {
          transform: scale(1.1);
        }

        &:not(:last-child) {
          margin-right: 24px;
        }
      }
    }

    &:hover,
    &:focus,
    &:focus-within {
      .PlaystoreReviewsList {
        animation-play-state: paused;
      }
    }
  }
}

.VideoPlayListContainer {
  .VideoPlayList {
    margin-top: 48px;

    .VideoPlayListItemContainer {
      .HomePageCard {
        padding-bottom: 24px;

        .HomePageCardImage {
          width: 100%;
          min-width: 100%;

          picture,
          img,
          iframe,
          video {
            display: block;
            min-height: 200px;
            object-fit: cover;
            width: 100%;
          }

          picture,
          img {
            min-width: 100%;
          }
        }

        .HomePageCardContent {
          margin-top: 12px;
        }
      }
    }

    .HomePageCardSlidePrev,
    .HomePageCardSlideNext {
      display: contents;

      svg {
        height: 120px;
        width: 120px;
        z-index: 1;
        top: calc(200px / 2 + 44px);
      }
    }

    .HomePageCardSlidePrev {
      svg {
        left: -36px;
      }
    }

    .HomePageCardSlideNext {
      svg {
        right: -36px;
      }
    }
  }

  @include for-mobile-only {
    .VideoPlayList {
      .VideoPlayListItemContainer {
        .HomePageCard {
          .HomePageCardImage {
            iframe,
            video {
              aspect-ratio: calc(4/3);
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}

.TalkToAnExpertContainer {
  margin-top: 24px;
  margin-bottom: 56px;

  @include for-mobile-only {
    margin-bottom: 36px;
  }

  @include for-tablet-and-desktop {
    .HomePageCard {
      .HomePageCardImage {
        width: 40%;
        margin-right: 0px;
        flex: 0 0 auto !important;
      }

      .HomePageCardContent {
        width: 50%;
        flex: 0 0 auto !important;
      }
    }
  }
}

@keyframes horizontal-scroll {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-50%, 0);
  }
}
