@import '../../../styles/variables';

.ReviewContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0px 2px 26px rgba(171, 171, 171, 0.5);
  background-color: rgba(255, 255, 255, 0.63);
  max-width: var(--reviewCardWidth);

  .ReviewContent {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .ReviewUserContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .ReviewUserCountry {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .ReviewUserCountryFlag {
        max-width: 24px;
        max-height: 24px;
        object-fit: contain;
      }

      .ReviewUserCountryFlag+.ReviewUserCountryName {
        margin-left: 12px;
      }
    }

    .ReviewStarContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .ReviewStarIcon {
        width: 18px;
        min-height: 18px;
        height: 18px;
        min-width: 18px;
        object-fit: contain;

        &:not(:last-child) {
          margin-right: 4px;
        }
      }
    }
  }

  .ReviewContent+.ReviewUserContainer {
    margin-top: 18px;
  }

  @include for-mobile-and-tablet {
    padding: 18px;

    .ReviewUserContainer {
      .ReviewUserCountry {
        .ReviewUserCountryFlag {
          transform: scale(calc(2 / 3));
        }

        .ReviewUserCountryFlag+.ReviewUserCountryName {
          margin-left: 12px;
        }
      }

      .ReviewStarContainer {
        .ReviewStarIcon {
          transform: scale(calc(3 / 4));

          &:not(:last-child) {
            margin-right: 0px;
          }
        }
      }
    }
  }

}
